.logo-box-footer {
  width: 350px;
  height: 100px;
  margin: auto;
}

.back {
  width: 100%;
  height: 375px;
  overflow-y: hidden;
}

.hover-icon {
  transition: filter 0.3s;
  cursor: pointer;
}

.social-box:hover .hover-icon {
  filter: brightness(1) invert(0);
  /* Change icon color to white on hover */
  opacity: 0.5;
}

.footer-heading {
  font-family: Clash-Display;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  line-height: 20px;
}

.footer-sub {
  font-family: Clash-Display;
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;
  line-height: 20px;
}

.footer-logo-box {
  width: 280px;
  height: 100px;
}

.footer-subtext {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #6C6C6C;
  line-height: 20px;
}

.position {
  display: table;
  margin: auto;
}

.all-buttons {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  height: 68px;
  color: #ffffff;
  background-color: #150F22;
  border: 1px solid #5E5E5E;
  padding: 10px;
  border-radius: 5px;
  margin-left: 15px;
}

.circle-in {
  position: relative;
}

/* .half-circle {
    width: 100%;
    height: 650px;
    background-color: #080216;
    border-top-left-radius: 3000px;
    border-top-right-radius: 3000px;
    display: table;
    margin: auto;
    overflow: hidden;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.half-circle::before {
  content: "";
  position: absolute;
  top: -7px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 650px;
  border-top-left-radius: 3000px;
  border-top-right-radius: 3000px;
  background: linear-gradient(90deg, #9533FF 30%, #6576FC 45%, #23D1F8 64%, #A025FD 92%, #BA02FE 98%);
  z-index: -1;
  filter: blur(20px);
  box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.5);
  animation: blink 1s infinite;
} */

.half-circle {
  background-image: url(../assets/images/footer-circle.png);
  background-position: center;
  height: 416px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% 86%;
  position: relative;
  padding-top: 160px;
}


/* .half-circle::before {
  content: "";
  position: absolute;
  top: -7px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 650px;
  border-top-left-radius: 75%;
  border-top-right-radius: 75%;
  background: linear-gradient(90deg, #9533FF 30%, #6576FC 45%, #23D1F8 64%, #A025FD 92%, #BA02FE 98%);
  z-index: -1;
  filter: blur(20px);
  box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.5);
  animation: blink 1s infinite;
} */

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.8;
  }
}

.footer-logo {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.footer-bottom-txt {
  font-family: Clash-Display;
  font-weight: 400;
  font-size: 14px;
  color: #6C6C6C;
  text-align: center;
  width: 100%;
  clear: both;
}

/* .footer-text-bottom {
  font-family: Clash-Display;
  font-weight: 400;
  font-size: 14px;
  color: #6C6C6C;
  position: relative;
  top: 65px;
  text-align: center;
  width: 100%;
} */

.footer-text {
  font-family: Clash-Display;
  font-weight: 400;
  font-size: 14px;
  color: white;
  line-height: 20px;
}

.box-footer {
  width: 80%;
  display: table;
  margin: auto;
  height: auto;
  margin-top: 100px;
}

.link-button {
  width: 100%;
  height: 60px;
  border: 1px solid #5E5E5E;
  font-family: Clash-Display;
  font-weight: 400;
  font-size: 18px;
  background-color: #150F22;
  border-radius: 10px;
  color: white;
  text-align: center;
}

.social-box {
  width: 22px;
  height: 22px;
  margin-left: 22px;
  filter: brightness(0) invert(1);
  opacity: 1;
}

.flex-social {
  display: flex;
}


@media (max-width:1440px) and (min-width:1199.9px) {
  .footer-text-bottom {
    left: 38%;
  }
}

@media (max-width:1200px) and (min-width:991.9px) {
  .footer-text-bottom {
    left: 38%;
  }
}

@media (max-width:992px) and (min-width:767.9px) {
  .space-s {
    margin-top: 20px;
  }

  .footer-logo {
    width: 75px;
    height: 75px;
  }

  .footer-text-bottom {
    left: 34%;
    top: 15%;
  }
}

@media (max-width:768px) and (min-width:575.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .footer-logo {
    width: 75px;
    height: 75px;
  }

  .footer-text-bottom {
    left: 30%;
    top: 15%;
  }

  .half-circle {
    background-image: url(../assets/images/footer-circle.png);
    background-position: center;
    height: 416px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100% 60%;
    position: relative;
    padding-top: 190px;
  }

}

@media (max-width:576px) and (min-width:424.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .footer-logo {
    width: 75px;
    height: 75px;
  }

  .footer-text-bottom {
    left: 15%;
    top: 15%;
    font-size: 12px;
  }

  .half-circle {
    background-image: url(../assets/images/footer-circle.png);
    background-position: center;
    height: 340px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100% 38%;
    position: relative;
    padding-top: 160px;
  }
}

@media (max-width:425px) and (min-width:319.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .footer-logo {
    width: 75px;
    height: 75px;
  }

  .footer-text-bottom {
    left: 15%;
    top: 15%;
    font-size: 12px;
  }

  .half-circle {
    background-image: url(../assets/images/footer-circle.png);
    background-position: center;
    height: 340px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100% 38%;
    position: relative;
    padding-top: 160px;
  }

}