/* Banner.css */
@font-face {
    font-family: Clash-Display;
    src: url('../assets/fonts/ClashDisplay-Regular.otf');
  }

.bg-secondary {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
    background-image: url('../assets/images/new_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
/* 
.bg-secondary::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); 
    z-index: 1;
}

.bg-secondary::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/new_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
} */

.box-banner {
    text-align: center;
    color: white;
    z-index: 1;
}

.heading {
    font-family: Clash-Display;
    font-weight: 600;
    font-size: 65px;
    color: white;
    line-height: 68px;
}

.sub-banner-text {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    color: #B1B1B1;
    line-height: 20px;
}

.align {
    z-index: 1;
}

  
.btn {
    font-family: Clash-Display;
    font-weight: 500;
    border: none;
    padding: 10px;
    font-size: 18px;
    position: relative;
    transform: skew(-15deg); /* Add skew transformation */
    z-index: 1; /* Ensure button content is below pseudo-element */
  }
  
  .btn::before {
    transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    content: '';
    width: 50%;
    height: 100%;
    background: transparent;
    border: 1px solid #568BFA;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; /* Ensure pseudo-element is above button content */
  }
  
  .btn .btn-text {
    font-family: Clash-Display;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: white;
    position: relative;
    z-index: 2; /* Ensure text is above the pseudo-element */
  }
  
  .btn:hover::before {
    background: #568BFA;
    width: 100%;
  }
  


  @media (max-width:992px) and (min-width:767.9px) {
   .sub-banner-text{
    display: none;
   }
  }
  @media (max-width:768px) and (min-width:575.9px) {
    .sub-banner-text{
      display: none;
     }
  }
  @media (max-width:576px) and (min-width:424.9px) {
    .sub-banner-text{
      display: none;
     }
  }
  @media (max-width:425px) and (min-width:319.9px) {
    .sub-banner-text{
      display: none;
     }
  }
  @media (max-width:320px) and (min-width:0px) {
    .sub-banner-text{
      display: none;
     }
  }
  