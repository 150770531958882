.box-about{
  width: 100%;
  border: 3px solid #568BFA;
  border-radius: 10px;
  padding: 10px;
  background-color: #080216;
  /* height: 520px; */
}
.main-box{
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.part{
  /* font-family: Clash-Display;
  font-weight: 600;
  font-size: 40px;
  color: white;
  padding: 10px;
  border-radius: 0px 0px 10px 5px;
  background-color: #080216;
  position: absolute;
  top: 24px; */
  font-family: Clash Display-Semibold, Helvetica;
  font-weight: 600;
  font-size: 48px;
  color: white;
  padding: 10px;
  border-radius: 0px 0px 10px 5px;
  /* background-color: #080216; */
  position: absolute;
  top: 7px;
  /* left: 25px; */
  /* border-right: 2px solid #568BFA !important; */
  /* border-bottom: 2px solid #568BFA !important; */
}
.sub-about{
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  color: white;
  line-height: 24px;
}
.overall{
  position: relative;
}



@media (max-width:1440px) and (min-width:1199.9px) {}
@media (max-width:1200px) and (min-width:991.9px) {}
@media (max-width:992px) and (min-width:767.9px) {
    .space-s{
        margin-top: 20px;
    }
}
@media (max-width:768px) and (min-width:575.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
    .part{
      font-size: 28px;
     }
}
@media (max-width:576px) and (min-width:424.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
    .part{
      font-size: 28px;
     }
     
}
@media (max-width:425px) and (min-width:319.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
    .part{
      font-size: 28px;
     }
}
@media (max-width:320px) and (min-width:0px) {
 .part{
  font-size: 28px;
 }
}

.part-custom{
  font-size: 50px;
  padding: 31px 58px 40px 92px;
  font-weight: 900;
  letter-spacing: 1px;
}