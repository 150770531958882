.search-bar {
    display: flex;
    align-items: center;
  }
  
  .search-container {
    position: relative;
    width: 100%;
    height: 55px;
  }
  
  .search-input {
    width: 100%;
    height: 55px;
    padding: 8px;
    border: 1px solid #568BFA;
    background-color: #150F22;
    color: white;
    border-radius: 4px;
    margin-right: 4px;
    padding-right: 30px; /* Adjust the padding to leave space for the icon */
  }
  
  .search-icon {
    position: absolute;
    right: 8px; /* Adjust the right position to align with the input field */
    color:#6C6C6C66;
    cursor: pointer;
    top: 50%;
    font-size: 20px;
    transform: translateY(-50%);
  }
  

  
  