@font-face {
    font-family: Clash-Display;
    src: url('../assets/fonts/ClashDisplay-Regular.otf');
  }
  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-Regular.ttf');
  }
.heading{
    font-family: Clash-Display;
    font-weight: 600;
    font-size: 65px;
    color: white;
    text-align: center;
    line-height: 68px;
}
.sub-text{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    color: #B1B1B1;
    text-align: center;
    line-height: 20px;
}
.news-box{
    border: 1px solid #568BFA;
    padding: 20px;
    border-radius: 10px;
}
.news-heading{
    font-family: Clash-Display;
    font-weight: 700;
    color: white;
    font-size: 24px;
    line-height: 30px;
}
.news-sub{
    font-family: Montserrat;
    font-weight: 500;
    font-size: 18px;
    color: white;
    line-height: 22px;
}



@media (max-width:992px) and (min-width:767.9px) {
    .sub-banner-text{
     display: none;
    }
   }
   @media (max-width:768px) and (min-width:575.9px) {
     .sub-banner-text{
       display: none;
      }
   }
   @media (max-width:576px) and (min-width:424.9px) {
    .news-box{
        margin: 1px;
    }
   }
   @media (max-width:425px) and (min-width:319.9px) {
    .news-box{
        margin: 1px;
    }
   }
   @media (max-width:320px) and (min-width:0px) {
    .news-box{
        margin: 1px;
    }
   }
   