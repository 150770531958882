@font-face {
    font-family: Clash-Display;
    src: url('../assets/fonts/ClashDisplay-Regular.otf');
  }
  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-Regular.ttf');
  }
  .heading{
    font-family: Clash-Display;
    font-weight: 600;
    font-size: 65px;
    color: white;
    text-align: center;
    line-height: 68px;
}
.location-heading{
  font-family: Clash-Display;
  font-weight: 700;
  font-size: 24px;
  color: white;
  text-align: center;
  line-height: 29px;
}
.location-flex{
  justify-content: center;
}
.loc-text-button{
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  color: white;
  text-align: center;
  line-height: 17px;
}
.loc-button{
  width: 190px;
  height: 60px;
  border-radius: 50px;
  border: 1px solid #568BFA;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.loc-button:hover{
  background: linear-gradient(90deg, #568BFA, #9435FF);
}
.flag-box{
  width: 56px;
  height: 28px;
}
.map-box{
  background-color: #231349;
  border: 1px solid #568BFA;
  padding: 10px;
  border-radius: 10px;
}
.sub-text{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 18px;
    color: #B1B1B1;
    text-align: center;
    line-height: 20px;
}
    .mapwrapper {
      border-radius: 10px;
      overflow: hidden;
    } 