 .pagination-dots {
    text-align: center;
    margin-top: 20px;
  }
  
  .dots {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #3E3E3E;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dots.active {
    background-color: #568BFA;
  } 
  