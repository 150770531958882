/* Banner.css */
@font-face {
  font-family: Clash-Display;
  src: url("../assets/fonts/ClashDisplay-Regular.otf");
}

/* .bg {
    position: relative;
    width: 100%;
    height:719px;
    background-image: url('../assets/images/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.bg {
  position: relative;
  width: 100%;
  height: 719px; /* Adjust the height as needed */
  overflow: hidden;
}
/* .bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
} */
.container-fluid {
  padding-right: 0;
  padding-left: 0;
  overflow-x: hidden;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
}
.content-align {
  margin-top: 40px;
}
/* .bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  transition: transform 0.5s ease;
  transform-origin: center center; 
} */

.zoomed::after {
  transform: scale(1.7); /* Increase scale value to zoom in */
}

.box-banner {
  text-align: center;
  color: white;
  z-index: 1;
}

.heading {
  font-family: Clash-Display;
  font-weight: 700;
  font-size: 65px;
  line-height: 68px;
}

.sub-text {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  line-height: 22px;
}

.align {
  z-index: 1;
}

.btn {
  font-family: Clash-Display;
  font-weight: 500;
  border: none;
  padding: 10px;
  font-size: 18px;
  position: relative;
  transform: skew(-15deg); /* Add skew transformation */
  z-index: 1; /* Ensure button content is below pseudo-element */
}

.btn::before {
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "";
  width: 50%;
  height: 100%;
  background: transparent;
  border: 1px solid #568bfa;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Ensure pseudo-element is above button content */
}

.btn .btn-text {
  font-family: Clash-Display;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: white;
  position: relative;
  z-index: 2; /* Ensure text is above the pseudo-element */
}

.btn:hover::before {
  background: #568bfa;
  width: 100%;
}


@media (max-width: 1440px) and (min-width: 1199.9px) {
}
@media (max-width: 1200px) and (min-width: 991.9px) {
}
@media (max-width: 992px) and (min-width: 767.9px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}
@media (max-width: 768px) and (min-width: 575.9px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}
@media (max-width: 576px) and (min-width: 424.9px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}
@media (max-width: 425px) and (min-width: 319.9px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}
@media (max-width: 320px) and (min-width: 0px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}

.button-2 {
  height: 57px;
  width: 125px;
  margin-left: 0px;
  margin-top: 3%;
}

.button-2 .overlap-group-3 {
  height: 57px;
  position: relative;
  background: transparent;
  border: none;
}

.button-2 .text-wrapper-4 {
  color: #ffffff;
  font-family: Clash-Display;
  font-size: 18px;
  font-weight: 00;
  left: 28px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 18px;
  width: 97px;
}

.button-2 .subtract-3 {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.button-2 .property-1-4-default {
  width: 154px;
}

.button-2 .property-1-4-variant-2 {
  background-image: url(../assets/images/subtract-1.svg);
  background-size: 100% 100%;
  width: 154px;
}
