@font-face {
  font-family: Clash-Display;
  src: url('./assets/fonts/ClashDisplay-Regular.otf');
}

@font-face {
  font-family: Montserrat;
  src: url('./assets/fonts/Montserrat-Regular.ttf');
}

.tab-button {
  font-family: Clash-Display;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: white;
  width: 100%;
  height: 75px;
  border-radius: 10px;
  background: linear-gradient(162.72deg, #231349 0%, rgba(21, 15, 34, 0.89) 88.14%);
  border: none;
}

.tab-button.active-tab {
  background: #568BFA;
}

.image-set {
  width: 100%;
  height: 485px;
  border-radius: 10px;
}

.image-set img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

@media (max-width:1440px) and (min-width:1199.9px) {
  .serv-img-box {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .serv-img-box img {
    max-width: 554px;
  }
}

@media (max-width:1200px) and (min-width:991.9px) {}

@media (max-width:992px) and (min-width:767.9px) {
  .space-s {
    margin-top: 20px;
  }
}

@media (max-width:768px) and (min-width:575.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .tab-hide {
    display: none;
  }
}

@media (max-width:576px) and (min-width:424.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .tab-hide {
    display: none;
  }
}

@media (max-width:425px) and (min-width:319.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .tab-hide {
    display: none;
  }
}