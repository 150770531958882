@font-face {
  font-family: Clash-Display;
  src: url("../assets/fonts/ClashDisplay-Regular.otf");
}

@font-face {
  font-family: Montserrat;
  src: url("../assets/fonts/Montserrat-Regular.ttf");
}

/* Animations */
@keyframes dotMoveLeft {
  0% {
    left: 0;
    opacity: 1
  }

  60% {
    left: calc(100% - 2px);
    opacity: 1
  }

  61% {
    left: calc(100% - 2px);
    opacity: 0
  }

  to {
    left: calc(100% - 2px);
    opacity: 1
  }
}

@keyframes dotMoveRight {
  0% {
    opacity: 1;
    right: 0
  }

  60% {
    opacity: 1;
    right: calc(100% - 2px)
  }

  61% {
    opacity: 0;
    right: calc(100% - 2px)
  }

  to {
    opacity: 1;
    right: calc(100% - 2px)
  }
}


@keyframes featuresItemMoveLeft {
  0% {
    transform: translateX(100%)
  }

  to {
    transform: translateX(0)
  }
}

@keyframes featuresItemMoveRight {
  0% {
    transform: translateX(-100%)
  }

  to {
    transform: translateX(0)
  }
}

/* Animations End */
.sub-heading {
  font-family: Clash-Display;
  font-weight: 600;
  font-size: 35px;
  color: white;
  line-height: 50px;
}

.sub-para {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  line-height: 24px;
}

.heading {
  font-family: Clash-Display;
  font-weight: 600;
  font-size: 65px;
  color: white;
  text-align: center;
  line-height: 68px;
}

.new-heading {
  font-family: Clash-Display;
  font-weight: 600;
  font-size: 50px;
  color: white;
  text-align: center;
  line-height: 55px;
}

.service-heading {
  font-family: Clash-Display;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: white;
  text-align: center;
  background: linear-gradient(to right, #231349 100%, #150f22 89%);
  border: 1px solid #568bfa;
  border-radius: 10px;
  /* border-image: linear-gradient(to bottom right, #568BFA, #0D0D0D, #568BFA) 1; */
}

.service-box {
  font-family: Clash-Display;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: white;
  text-align: center;
  background: linear-gradient(to right, #231349 100%, #150f22 89%);
  border: 1px solid #568bfa;
  border-radius: 50px;
}

.img-overall {
  height: 450px;
}

.sb-1 {
  margin-top: 20px;
}

.sb-2 {
  margin-top: 25px;
}

.sb-3 {
  margin-top: 30px;
}

.sb-4 {
  margin-top: 20px;
}

.no-gap-cols>div[class^="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.img-main {
  width: 300px !important;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.sub-text {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  text-align: center;
  line-height: 22px;
}

.box {
  width: 100%;
  border: 3px solid white;
  border-radius: 10px;
  padding: 10px;
}

.size-s1 {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 100px;
  top: 90px;
}

.size-s2 {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 270px;
  bottom: 80px;
}

.size-s3 {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 350px;
  top: 30px;
}

.size-s4 {
  width: 110px;
  height: 110px;
  position: absolute;
  left: 170px;
  top: 25px;
}

.size-s5 {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 170px;
  bottom: 25px;
}

.size-s6 {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 370px;
  bottom: 25px;
}

/* @keyframes bubbleAnimation {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.size-s1,
.size-s2,
.size-s3,
.size-s4,
.size-s5,
.size-s6 {
  animation: bubbleAnimation 5s ease-in-out infinite;
}

.size-s1 {
  animation-delay: 0.5s;
}
.size-s2 {
  animation-delay: 1s;
}
.size-s3 {
  animation-delay: 1.5s;
}
.size-s4 {
  animation-delay: 2s;
}
.size-s5 {
  animation-delay: 2.5s;
}
.size-s6 {
  animation-delay: 3s;
}
 */

.primary {
  font-family: Clash-Display;
  font-weight: 500;
  width: 180px;
  background-color: #568bfa;
  color: white;
  font-size: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.img-main img {
  animation: bounce 1.5s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-25px);
  }
}

.secondary {
  font-family: Clash-Display;
  font-weight: 500;
  background-color: #2f2f2f82;
  color: white;
  font-size: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  width: 180px;
}

.response {
  margin-top: 120px;
}

.box2 {
  height: 300px;
  position: relative;
  overflow: hidden;
}

.shine-curve-btn {
  align-items: center;
  background: linear-gradient(#080216, #080216) padding-box, linear-gradient(320deg, #568BFA -10%, #3a64fa00 20%, #18c3fa00 30%, #568BFA 50%, #3a64fa00 70%, #18c3fa00 80%, #568BFA 110%) border-box;
  background-size: 200% 200%;
  border: 2px solid #0000;
  border-radius: 50px;
  cursor: pointer;
  color: #fff;
  display: flex;
  padding: 16px 24px;
  text-decoration: none;
  font-family: Clash-Display;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.shine-curve-btn:hover {
  animation: a 1s ease-in-out .1s
}

@keyframes a {
  0% {
    background-position: 0 0
  }

  50% {
    background-position: 100% 100%
  }
}

@media (max-width: 992px) and (min-width: 767.9px) {
  .img-overall {
    display: none;
  }

  .box2 {
    display: none;
  }

  .response {
    margin-top: 0px;
  }

}

@media (max-width: 768px) and (min-width: 575.9px) {
  .img-overall {
    display: none;
  }

  .box2 {
    display: none;
  }

  .response {
    margin-top: 0px;
  }

}

@media (max-width: 576px) and (min-width: 424.9px) {
  .img-overall {
    display: none;
  }

  .box2 {
    display: none;
  }

  .response {
    margin-top: 0px;
  }
}

@media (max-width: 425px) and (min-width: 319.9px) {
  .img-overall {
    display: none;
  }

  .box2 {
    display: none;
  }

  .response {
    margin-top: 0px;
  }
}

@media (max-width: 320px) and (min-width: 0px) {
  .img-overall {
    display: none;
  }

  .box2 {
    display: none;
  }

  .response {
    margin-top: 0px;
  }
}

.property-default-wrapper {
  height: 57px;
}

.property-default-wrapper .div {
  height: 57px;
  position: relative;
}

.property-default-wrapper .text-wrapper-2 {
  color: #ffffff;
  font-family: Clash-Display;
  font-size: 18px;
  font-weight: 600;
  left: 28px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.property-default-wrapper .img {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.property-default-wrapper.property-1-0-default {
  width: 125px;
}

.property-default-wrapper.property-1-0-variant-2 {
  width: 133px;
}

.property-default-wrapper.property-1-0-default .div {
  width: 178px;
}

.property-default-wrapper.property-1-0-variant-2 .div {
  background-image: url(../assets/images/subtract-4.svg);
  background-size: 100% 100%;
  width: 206px;
}

.frame-123 {
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 3px solid;
  border-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  padding: 51px;
  /* position: absolute; */
  top: 1800px;
  width: 979px;
}

.frame-124 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  height: 182px;
  margin-left: -9.23px;
  position: relative;
}

.frame-125 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  position: relative;
}

.text-wrapper-87 {
  color: #ffffff;
  font-family: Clash-Display;
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 46.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-88 {
  color: #b1b1b1;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.custom1 {
  display: block;
  margin-left: 13%;
}

.group-64 {
  height: 23px;
  left: 19px;
  position: absolute;
  top: 19px;
  width: 23px;
}

.overlap-group-16 {
  height: 37px;
  left: -7px;
  position: relative;
  top: -7px;
  width: 37px;
}

.mask-group-16 {
  height: 31px;
  left: 3px;
  position: absolute;
  top: 3px;
  transform: rotate(12.32deg);
  width: 31px;
}

@keyframes bounce1 {
  70% {
    transform: translateY(0%);
  }

  80% {
    transform: translateY(-15%);
  }

  90% {
    transform: translateY(0%);
  }

  95% {
    transform: translateY(-7%);
  }

  97% {
    transform: translateY(0%);
  }

  99% {
    transform: translateY(-3%);
  }

  100% {
    transform: translateY(0);
  }
}

.mask-group-17 {
  height: 27px;
  left: 5px;
  position: absolute;
  top: 5px;
  transform: rotate(12.32deg);
  width: 27px;
}

.group-65 {
  height: 61px;
  left: 127px;
  position: absolute;
  top: 68px;
  transform: rotate(-32.23deg);
  animation: bounce1 2s ease infinite;
  width: 61px;
}

.overlap-group-17 {
  height: 49px;
  left: -13px;
  position: relative;
  top: -13px;
  width: 49px;
}

.mask-group-18 {
  height: 36px;
  left: 7px;
  position: absolute;
  top: 7px;
  transform: rotate(32.23deg);
  width: 36px;
}

.mask-group-19 {
  height: 32px;
  left: 9px;
  position: absolute;
  top: 9px;
  transform: rotate(32.23deg);
  width: 32px;
}

.group-66 {
  height: 61px;
  left: 193px;
  position: absolute;
  top: 138px;
  width: 61px;
}

.mask-group-20 {
  height: 27px;
  left: 17px;
  position: absolute;
  top: 17px;
  width: 27px;
}

.group-67 {
  height: 61px;
  left: 76px;
  position: absolute;
  top: 0;
  width: 61px;
}

.overlap-group-18 {
  background-image: url(../assets/images/mask-group-21.png);
  background-size: 100% 100%;
  height: 27px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 27px;
}

.mask-group-21 {
  height: 23px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 23px;
}

.group-68 {
  height: 61px;
  left: 76px;
  position: absolute;
  top: 138px;
  width: 61px;
}

.overlap-group-19 {
  background-image: url(../assets/images/mask-group-23.png);
  background-size: 100% 100%;
  height: 27px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 27px;
}

.group-69 {
  height: 61px;
  left: 193px;
  position: absolute;
  top: 0;
  width: 61px;
}

.overlap-group-20 {
  background-image: url(../assets/images/mask-group-25.png);
  background-size: 100% 100%;
  height: 27px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 27px;
}

.alpha-updated-3 {
  background-color: #080216;
  height: 7966px;
  overflow: hidden;
  position: relative;
  width: 1366px;
}

.overlap-11 {
  height: 2104px;
  left: 0;
  position: absolute;
  top: -258px;
  width: 2157px;
}

.ellipse-11 {
  height: 1117px;
  left: 1040px;
  position: absolute;
  top: 0;
  width: 1117px;
}

.rectangle-19 {
  background-color: #080216;
  height: 719px;
  left: 0;
  position: absolute;
  top: 258px;
  width: 1366px;
}

.rectangle-20 {
  height: 719px;
  left: 0;
  position: absolute;
  top: 258px;
  width: 1366px;
}

.group-52 {
  height: 212px;
  left: 20px;
  position: absolute;
  top: 572px;
  width: 152px;
}

.group-53 {
  height: 199px;
  left: 51px;
  position: absolute;
  top: 6px;
  width: 111px;
}

.text-wrapper-75 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.text-wrapper-76 {
  color: #464646;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 42px;
  white-space: nowrap;
}

.text-wrapper-77 {
  color: #464646;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 88px;
  white-space: nowrap;
}

.text-wrapper-78 {
  color: #464646;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 180px;
  white-space: nowrap;
}

.text-wrapper-79 {
  color: #464646;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 134px;
  white-space: nowrap;
}

.group-54 {
  background-color: #353535;
  border-radius: 100px;
  height: 212px;
  left: 0;
  position: absolute;
  top: 0;
  width: 27px;
}

.ellipse-12 {
  background-color: var(--variable-collection-secondary-color);
  border-radius: 8.5px;
  height: 17px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 17px;
}

.ellipse-13 {
  background-color: #555557;
  border-radius: 8.5px;
  height: 17px;
  left: 5px;
  position: absolute;
  top: 47px;
  width: 17px;
}

.ellipse-14 {
  background-color: #555557;
  border-radius: 8.5px;
  height: 17px;
  left: 5px;
  position: absolute;
  top: 93px;
  width: 17px;
}

.ellipse-15 {
  background-color: #555557;
  border-radius: 8.5px;
  height: 17px;
  left: 5px;
  position: absolute;
  top: 139px;
  width: 17px;
}

.ellipse-16 {
  background-color: #555557;
  border-radius: 8.5px;
  height: 17px;
  left: 5px;
  position: absolute;
  top: 185px;
  width: 17px;
}

.rectangle-21 {
  background-color: #080216;
  border-radius: 50px 50px 0px 0px;
  height: 958px;
  left: 0;
  position: absolute;
  top: 977px;
  width: 1366px;
}

.group-55 {
  height: 91px;
  left: 324px;
  position: absolute;
  top: 1107px;
  width: 723px;
}

.text-wrapper-80 {
  color: var(--collection-1-white);
  font-family: "Clash Display-Semibold", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 197px;
  letter-spacing: 0;
  line-height: 51.7px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.text-wrapper-81 {
  color: #b1b1b1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 69px;
}

.ellipse-17 {
  background-color: #0051ff;
  border-radius: 558px/29.5px;
  filter: blur(826px);
  height: 59px;
  left: 112px;
  opacity: 0.6;
  position: absolute;
  top: 1857px;
  width: 1116px;
}

.group-56 {
  height: 113px;
  left: 253px;
  position: absolute;
  top: 575px;
  width: 865px;
}

.text-wrapper-82 {
  color: var(--collection-1-white);
  font-family: "Clash Display-Semibold", Helvetica;
  font-size: 65px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 67.3px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.text-wrapper-83 {
  color: #b1b1b1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 118px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 69px;
  width: 626px;
}

.frame-120 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  justify-content: center;
  left: 20px;
  position: absolute;
  top: 1107px;
}

.group-57 {
  background-color: #353535;
  border-radius: 100px;
  height: 212px;
  position: relative;
  width: 27px;
}

.group-58 {
  height: 199px;
  margin-right: -10px;
  position: relative;
  width: 130px;
}

.group-59 {
  height: 57px;
  left: 180px;
  position: absolute;
  top: 306px;
  width: 897px;
}

.logo-8 {
  height: 50px !important;
  top: 5px !important;
  width: 177px !important;
}

.button-11 {
  left: 772px !important;
  position: absolute !important;
  top: 0 !important;
}

.frame-121 {
  align-items: flex-start;
  display: inline-flex;
  gap: 22px;
  left: 225px;
  position: absolute;
  top: 16px;
}

.frame-122 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.text-wrapper-84 {
  color: #ffffff;
  font-family: "Clash Display-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.line-2 {
  height: 2px;
  position: relative;
  width: 31px;
}

.text-wrapper-85 {
  color: #ffffff;
  font-family: "Clash Display-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.group-60 {
  height: 19px;
  position: relative;
  width: 102px;
}

.text-wrapper-86 {
  color: #ffffff;
  font-family: "Clash Display-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.vector-10 {
  height: 7px;
  left: 89px;
  position: absolute;
  top: 7px;
  width: 12px;
}

.group-61 {
  height: 19px;
  position: relative;
  width: 144px;
}

.vector-11 {
  height: 7px;
  left: 131px;
  position: absolute;
  top: 7px;
  width: 12px;
}

.group-62 {
  height: 199px;
  margin-left: 135px;
  position: relative;
  width: 253.45px;
}

.group-63 {
  height: 61px;
  left: 6px;
  position: absolute;
  top: 68px;
  transform: rotate(-12.32deg);
  animation: bounce1 3s ease infinite;
  width: 61px;
}

.overlap-12 {
  height: 61px;
  position: relative;
}

.ellipse-18 {
  background-color: var(--variable-collection-secondary-color);
  border: 1px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 23.78px;
  filter: blur(6px);
  height: 48px;
  left: 7px;
  position: absolute;
  top: 7px;
  transform: rotate(-20deg);
  width: 48px;
}

.ellipse-19 {
  background-color: #464646;
  border: 1px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 23.78px;
  height: 48px;
  left: 7px;
  position: absolute;
  top: 7px;
  transform: rotate(-20deg);
  width: 48px;
}

.group-64 {
  height: 23px;
  left: 19px;
  position: absolute;
  top: 19px;
  width: 23px;
}

.overlap-group-16 {
  height: 37px;
  left: -7px;
  position: relative;
  top: -7px;
  width: 37px;
}

.mask-group-16 {
  height: 31px;
  left: 3px;
  position: absolute;
  top: 3px;
  transform: rotate(12.32deg);
  width: 31px;
}

.mask-group-17 {
  height: 27px;
  left: 5px;
  position: absolute;
  top: 5px;
  transform: rotate(12.32deg);
  width: 27px;
}

.group-65 {
  height: 61px;
  left: 127px;
  position: absolute;
  top: 68px;
  transform: rotate(-32.23deg);
  animation: bounce1 4s ease infinite;
  width: 61px;
}

.overlap-group-17 {
  height: 49px;
  left: -13px;
  position: relative;
  top: -13px;
  width: 49px;
}

.mask-group-18 {
  height: 36px;
  left: 7px;
  position: absolute;
  top: 7px;
  transform: rotate(32.23deg);
  width: 36px;
}

.mask-group-19 {
  height: 32px;
  left: 9px;
  position: absolute;
  top: 9px;
  transform: rotate(32.23deg);
  width: 32px;
}

.group-66 {
  height: 61px;
  left: 193px;
  position: absolute;
  top: 138px;
  width: 61px;
  animation: bounce1 5s ease infinite;
}

.mask-group-20 {
  height: 27px;
  left: 17px;
  position: absolute;
  top: 17px;
  width: 27px;
}

.group-67 {
  height: 61px;
  left: 76px;
  position: absolute;
  top: 0;
  width: 61px;
  animation: bounce1 6s ease infinite;
}

.overlap-group-18 {
  background-image: url(../assets/images/mask-group-21.png);
  background-size: 100% 100%;
  height: 27px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 27px;
}

.mask-group-21 {
  height: 23px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 23px;
}

.group-68 {
  height: 61px;
  left: 76px;
  position: absolute;
  top: 138px;
  width: 61px;
  animation: bounce1 7s ease infinite;
}

.overlap-group-19 {
  background-image: url(../assets/images/mask-group-23.png);
  background-size: 100% 100%;
  height: 27px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 27px;
}

.group-69 {
  height: 61px;
  left: 193px;
  position: absolute;
  top: 0;
  width: 61px;
  animation: bounce1 8s ease infinite;
}

.overlap-group-20 {
  background-image: url(../assets/images/mask-group-25.png);
  background-size: 100% 100%;
  height: 27px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 27px;
}

.group-70 {
  height: 444px;
  left: 121px;
  position: absolute;
  top: 1278px;
  width: 1116px;
}

.overlap-13 {
  height: 445px;
  position: relative;
}

.frame-126 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 2px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 141px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 73px;
  padding: 30px 40px;
  position: absolute;
  top: 99px;
  width: 200px;
}

.text-wrapper-89 {
  color: #ffffff;
  font-family: "Clash Display-Semibold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-127 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 141px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 73px;
  padding: 30px 40px;
  position: absolute;
  top: 190px;
  width: 200px;
}

.frame-128 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 141px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 851px;
  padding: 30px 40px;
  position: absolute;
  top: 190px;
  width: 200px;
}

.frame-129 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 141px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 851px;
  padding: 30px 40px;
  position: absolute;
  top: 99px;
  width: 200px;
}

.vector-12 {
  height: 385px;
  left: 358px;
  position: absolute;
  top: 0;
  width: 409px;
}

.ellipse-20 {
  background-color: #0051ff;
  border-radius: 558px/31.5px;
  filter: blur(246px);
  height: 63px;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 381px;
  width: 1116px;
}

.vector-13 {
  height: 1px;
  left: 309px;
  object-fit: cover;
  position: absolute;
  top: 408px;
  width: 513px;
}

.frame-130 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 141px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 822px;
  padding: 30px 40px;
  position: absolute;
  top: 372px;
  width: 231px;
}

.frame-131 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 141px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 73px;
  padding: 30px 40px;
  position: absolute;
  top: 281px;
}

.frame-132 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 141px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 851px;
  padding: 30px 40px;
  position: absolute;
  top: 281px;
  width: 200px;
}

.vector-14 {
  height: 276px;
  left: 272px;
  position: absolute;
  top: 135px;
  width: 106px;
}

.frame-133 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 2px solid;
  border-color: var(--variable-collection-secondary-color);
  border-radius: 141px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 73px;
  padding: 30px 40px;
  position: absolute;
  top: 372px;
  width: 231px;
}

.text-wrapper-90 {
  color: #ffffff;
  font-family: "Clash Display-Semibold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.6px;
  margin-left: -6px;
  margin-right: -4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.vector-15 {
  height: 276px;
  left: 746px;
  position: absolute;
  top: 136px;
  width: 106px;
}

.button-12 {
  left: 621px !important;
  position: absolute !important;
  top: 723px !important;
}

.frame-134 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 35px;
  left: 272px;
  position: absolute;
  top: 1995px;
}

.make-your-work-fast {
  color: var(--collection-1-white);
  font-family: "Clash Display-Regular", Helvetica;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 51.7px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 822px;
}

.span {
  color: #ffffff;
  font-family: "Clash Display-Regular", Helvetica;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 51.7px;
}

.text-wrapper-91 {
  font-family: "Montserrat", Helvetica;
  font-weight: 600;
}

.text-wrapper-92 {
  color: #b1b1b1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.frame-135 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  justify-content: center;
  left: 20px;
  position: absolute;
  top: 1995px;
}

.frame-136 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  justify-content: center;
  left: 20px;
  position: absolute;
  top: 3157px;
}

.group-71 {
  height: 91px;
  left: 324px;
  position: absolute;
  top: 3157px;
  width: 723px;
}

.text-wrapper-93 {
  color: var(--collection-1-white);
  font-family: "Clash Display-Semibold", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 205px;
  letter-spacing: 0;
  line-height: 51.7px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame-137 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 50px;
  left: 193px;
  position: absolute;
  top: 3353px;
  width: 480px;
}

.frame-138 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  position: relative;
  width: 480px;
}

.text-wrapper-94 {
  color: var(--collection-1-white);
  font-family: "Clash Display-Semibold", Helvetica;
  font-size: 35px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -1.5px;
  margin-right: -1.5px;
  margin-top: -1px;
  position: relative;
  width: 483px;
}

.frame-139 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  margin-left: -3.5px;
  margin-right: -3.5px;
  padding: 10px;
  position: relative;
  width: 487px;
}

.text-wrapper-95 {
  color: #b1b1b1;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -5.5px;
  margin-right: -5.5px;
  margin-top: -1px;
  position: relative;
  width: 478px;
}

.button-13 {
  position: relative !important;
}

.frame-140 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 50px;
  left: 695px;
  position: absolute;
  top: 3829px;
  width: 480px;
}

.text-wrapper-96 {
  color: #b1b1b1;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -5.5px;
  margin-right: -5.5px;
  margin-top: -1px;
  position: relative;
  width: 478px;
}

.frame-141 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 50px;
  left: 193px;
  position: absolute;
  top: 4286px;
  width: 480px;
}

.frame-142 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 50px;
  left: 193px;
  position: absolute;
  top: 4754px;
  width: 480px;
}

.group-72 {
  height: 337px;
  left: 695px;
  position: absolute;
  top: 3352px;
  width: 480px;
}

.group-73 {
  height: 91px;
  left: 108px;
  position: absolute;
  top: 246px;
  width: 265px;
}

.overlap-group-21 {
  height: 91px;
  position: relative;
}

.rectangle-22 {
  height: 89px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 261px;
}

.ellipse-21 {
  border: 4px solid;
  border-color: #4278e1;
  border-radius: 132.36px/14px;
  height: 28px;
  left: 0;
  position: absolute;
  top: 63px;
  width: 265px;
}

.group-74 {
  height: 227px;
  left: 0;
  position: absolute;
  top: 0;
  width: 480px;
}

.overlap-14 {
  height: 227px;
  position: relative;
}

.group-75 {
  height: 139px;
  left: 0;
  position: absolute;
  top: 45px;
  width: 480px;
}

.crypto-9 {
  height: 139px;
  left: 0;
  position: absolute;
  top: 0;
  width: 240px;
}

.crypto-10 {
  height: 139px;
  left: 240px;
  position: absolute;
  top: 0;
  width: 240px;
}

.cypto-5 {
  height: 227px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 393px;
}

.group-76 {
  height: 337px;
  left: 193px;
  position: absolute;
  top: 3819px;
  width: 480px;
}

.crypto-11 {
  height: 139px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 240px;
}

.crypto-12 {
  height: 139px;
  left: 240px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 240px;
}

.cypto-6 {
  height: 227px;
  left: 39px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 393px;
}

.group-77 {
  height: 337px;
  left: 695px;
  position: absolute;
  top: 4287px;
  width: 480px;
}

.group-78 {
  height: 337px;
  left: 695px;
  position: absolute;
  top: 4755px;
  width: 480px;
}

.group-79 {
  height: 91px;
  left: 324px;
  position: absolute;
  top: 5242px;
  width: 723px;
}

.text-wrapper-97 {
  color: var(--collection-1-white);
  font-family: "Clash Display-Semibold", Helvetica;
  font-size: 50px;
  font-weight: 400;
  left: 222px;
  letter-spacing: 0;
  line-height: 51.7px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.group-80 {
  height: 212px;
  left: 20px;
  position: absolute;
  top: 5242px;
  width: 171px;
}

.group-81 {
  height: 199px;
  left: 51px;
  position: absolute;
  top: 6px;
  width: 130px;
}

.overlap-15 {
  height: 22px;
  left: 324px;
  position: absolute;
  top: 6227px;
  width: 721px;
}

.group-82 {
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 721px;
}

.text-wrapper-98 {
  color: #b1b1b1;
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.group-83 {
  height: 212px;
  left: 20px;
  position: absolute;
  top: 6158px;
  width: 171px;
}

.frame-143 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 80px;
  left: 242px;
  position: absolute;
  top: 2219px;
}

.group-84 {
  height: 531.9px;
  position: relative;
  width: 355.25px;
}

.overlap-16 {
  height: 532px;
  position: relative;
  width: 355px;
}

.group-85 {
  height: 121px;
  left: 0;
  position: absolute;
  top: 411px;
  width: 355px;
}

.group-86 {
  height: 121px;
}

.overlap-group-22 {
  height: 121px;
  position: relative;
  width: 355px;
}

.rectangle-23 {
  height: 118px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 350px;
}

.ellipse-22 {
  border: 4px solid;
  border-color: #4278e1;
  border-radius: 177.62px/18.68px;
  height: 37px;
  left: 0;
  position: absolute;
  top: 84px;
  width: 355px;
}

.untitled-design-4 {
  height: 470px;
  left: 33px;
  position: absolute;
  top: 0;
  width: 289px;
}

.frame-144 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 30px;
  position: relative;
  width: 882px;
}

.frame-145 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 73px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 198px;
}

.measurement-4 {
  color: #ffffff;
  font-family: "Clash Display-Semibold", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-146 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 195px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5550px;
}

.clip-path-group-11 {
  height: 157.89px;
  position: relative;
  width: 140px;
}

.frame-147 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 145px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5789px;
}

.clip-path-group-12 {
  height: 139.35px;
  position: relative;
  width: 140px;
}

.frame-148 {
  align-items: flex-start;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  left: -15px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5550px;
}

.clip-path-group-13 {
  height: 168.77px;
  position: relative;
  width: 140px;
}

.frame-149 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: -65px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5789px;
}

.clip-path-group-14 {
  height: 83.41px;
  position: relative;
  width: 100px;
}

.frame-150 {
  align-items: flex-start;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  left: 405px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5550px;
}

.clip-path-group-15 {
  height: 172.29px;
  margin-bottom: -3.29px;
  position: relative;
  width: 140px;
}

.frame-151 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 355px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5789px;
}

.clip-path-group-16 {
  height: 159.09px;
  position: relative;
  width: 140px;
}

.overlap-17 {
  border-radius: 10px;
  height: 219px;
  left: 614px;
  position: absolute;
  top: 5550px;
  width: 190px;
}

.frame-152 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 219px;
  justify-content: space-around;
  left: 0;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 0;
  width: 190px;
}

.clip-path-group-17 {
  height: 153px;
  left: 25px;
  position: absolute;
  top: 33px;
  width: 140px;
}

.frame-153 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 565px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5789px;
}

.clip-path-group-18 {
  height: 103.1px;
  position: relative;
  width: 140px;
}

.frame-154 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 825px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5550px;
}

.clip-path-group-19 {
  height: 150.95px;
  position: relative;
  width: 140px;
}

.frame-155 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 775px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5789px;
}

.clip-path-group-20 {
  height: 83.43px;
  position: relative;
  width: 140px;
}

.frame-156 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 1035px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5550px;
}

.clip-path-group-21 {
  height: 93.83px;
  position: relative;
  width: 140px;
}

.frame-157 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 985px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5789px;
}

.clip-path-group-22 {
  height: 59.42px;
  position: relative;
  width: 140px;
}

.frame-158 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 1245px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5550px;
  width: 190px;
}

.clip-path-group-23 {
  height: 88.16px;
  position: relative;
  width: 96px;
}

.frame-159 {
  align-items: center;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom,
      rgb(86, 139, 250),
      rgba(13.46, 13.46, 13.46, 0) 39.1%,
      rgba(13, 13, 13, 0) 68.84%,
      rgb(86, 139, 250) 100%) 1;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  height: 219px;
  justify-content: center;
  left: 1195px;
  overflow: hidden;
  padding: 25px;
  position: absolute;
  top: 5789px;
}

.clip-path-group-24 {
  height: 58.18px;
  position: relative;
  width: 140px;
}

.footer-desktop-final-2 {
  height: 710px;
  left: 0;
  position: absolute;
  top: 7256px;
  width: 1366px;
}

.blog {
  align-items: flex-start;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: #568bfa;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  left: 195px;
  overflow: hidden;
  padding: 15px 14px;
  position: absolute;
  top: 6447px;
}

.frame-160 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.rectangle-24 {
  height: 237px;
  object-fit: cover;
  position: relative;
  width: 285px;
}

.frame-161 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 282px;
}

.frame-162 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  position: relative;
}

.lorem-ipsum-dolor-3 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 279px;
}

.frame-163 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: space-around;
  padding: 10px;
  position: relative;
  width: 279px;
}

.text-wrapper-99 {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -6.5px;
  margin-right: -6.5px;
  margin-top: -1px;
  position: relative;
  width: 272px;
}

.frame-164 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 267px;
}

.text-wrapper-100 {
  color: #ffffff;
  font-family: "Clash Display-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -11px;
  margin-right: -11px;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: 269px;
}

.blog-2 {
  align-items: flex-start;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: #568bfa;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  left: 527px;
  overflow: hidden;
  padding: 15px 14px;
  position: absolute;
  top: 6447px;
}

.blog-3 {
  align-items: flex-start;
  background: linear-gradient(180deg,
      rgb(35, 19, 73) 0%,
      rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: #568bfa;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  left: 859px;
  overflow: hidden;
  padding: 15px 14px;
  position: absolute;
  top: 6447px;
}

.button-14 {
  left: 620px !important;
  position: absolute !important;
  top: 7049px !important;
}

.button-6 {
  all: unset;
  box-sizing: border-box;
  height: 57px;
  width: 125px;
}

.button-6 .overlap-group-5 {
  height: 57px;
  position: relative;
}

.button-6 .text-wrapper-6 {
  color: #ffffff;
  font-family: Clash-Display;
  font-size: 18px;
  font-weight: 600;
  left: 28px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.button-6 .subtract-5 {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.button-6 .property-1-7-default {
  width: 102px;
}

.button-6 .property-1-7-variant-2 {
  background-image: url(../assets/images/subtract-20.svg);
  background-size: 100% 100%;
  width: 119px;
}

/* Center Services Images Animation */

.left-right-serv-con {
  margin-top: 160px;
}

.serv-img-box {
  width: 100%;
  text-align: center;
  position: relative;
}

.serv-img-box img {
  max-width: 100%;
  height: auto;
}

.serv-img-box::before {
  content: '';
  width: 2px !important;
  height: 308px !important;
  background-image: url('../assets/images/vertical-line.png');
  background-repeat: repeat-y;
  position: absolute;
  left: 10px;
  bottom: -46px;
  z-index: -1;
}

.serv-img-box::after {
  content: '';
  width: 2px !important;
  height: 308px !important;
  background-image: url('../assets/images/vertical-line.png');
  background-repeat: repeat-y;
  position: absolute;
  right: 10px;
  bottom: -46px;
  z-index: -1;
}


@media (max-width:1440px) and (min-width:1199.9px) {
  .serv-img-box {
    width: 100%;
    text-align: center;
    margin-top: 72px;
  }

  .serv-img-box img {
    max-width: 554px;
  }
}


@media (max-width:1200px) and (min-width:1025px) {
  .serv-img-box {
    width: 100%;
    text-align: center;
    margin-top: 172px;
  }

  .serv-img-box img {
    max-width: 410px;
  }
}

@media screen and (max-width: 1024px) {

  .serv-img-box,
  .serv-img-box-bottom {
    display: none;
  }

  .serv-bot-box {
    margin-top: 30px;
  }

}


.animation-features {
  align-items: end;
  display: flex;
  padding-bottom: 50px
}

.animation-features-item-label {
  border: 2px solid #3a64fa;
  border-radius: 50px;
  box-sizing: border-box;
  color: #fff;
  font-family: Hanken Grotesk, Arial, sans-serif;
  font-size: 14px
}

.animation-features-ball-left {
  background-color: #3a64fa;
  border-radius: 50%;
  height: 8px;
  margin-top: -3px;
  position: absolute;
  width: 8px
}

.animation-features-ball-left {
  animation: dotMoveLeft 3s linear infinite
}

.animation-features-item:nth-child(2) .animation-features-ball-left {
  animation-delay: .6s
}

.animation-features-item:nth-child(3) .animation-features-ball-left {
  animation-delay: 1.2s
}

.animation-features-input {
  flex-direction: column;
  gap: 22px
}

.animation-features-input,
.animation-features-output {
  display: flex;
  width: 100%
}

.animation-features-path {
  display: flex;
  flex-grow: 1;
  position: relative
}

.animation-features-input .animation-features-item-label {
  align-items: center;
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  background: linear-gradient(136deg, rgba(49, 23, 114, .5) 11.07%, rgba(49, 23, 114, 0) 61.91%);
  display: flex;
  gap: 10px;
  justify-content: center;
  min-width: 164px;
  padding: 16px 24px
}

.animation-features-input .animation-features-item {
  align-items: center;
  display: flex;
  position: relative;
  right: -8px;
}

.animation-features-input .animation-features-item:first-child {
  animation: featuresItemMoveLeft .8s forwards
}

.animation-features-input .animation-features-item:nth-child(2) {
  animation: featuresItemMoveLeft .8s forwards;
  animation-delay: .3s
}

.animation-features-input .animation-features-item:nth-child(3) {
  animation: featuresItemMoveLeft .8s forwards;
  animation-delay: .6s
}



.animation-features-output {
  display: flex;
  flex-direction: column;
  gap: 22px
}

.animation-features-output {
  animation: featuresItemMoveRight .8s forwards;
  animation-delay: .8s
}

.animation-features-output .animation-features-item {
  align-items: center;
  display: flex;
  position: relative;
  left: -12px;
}

.animation-features-output .animation-features-item:first-child {
  animation: featuresItemMoveRight .8s forwards
}

.animation-features-output .animation-features-item:nth-child(2) {
  animation: featuresItemMoveRight .8s forwards;
  animation-delay: .3s
}

.animation-features-output .animation-features-item:nth-child(3) {
  animation: featuresItemMoveRight .8s forwards;
  animation-delay: .6s
}


.animation-features-ball-right {
  background-color: #3a64fa;
  border-radius: 50%;
  height: 8px;
  margin-top: -4px;
  position: absolute;
  width: 8px;
}

.animation-features-ball-right {
  animation: dotMoveRight 3s linear infinite;
}


.animation-features-right {
  animation: dotMoveRight 3s linear infinite
}

.animation-features-output .animation-features-item-label {
  align-items: center;
  background-color: #080216;
  display: inline-flex;
  justify-content: center;
  opacity: 0;
  padding: 4px 24px;
  position: absolute;
  z-index: 1
}

.animation-features-output .animation-features-item {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative
}

.animation-features-path img {
  width: 100%
}

.serv-img-box-bottom {
  position: relative;
  top: 50%;
}

.serv-img-box-bottom::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-image: url('../assets/images/horizontal-bottom.png');
  background-repeat: repeat-x;
  top: 50%;
}


@media (max-width: 1024px) {
  .serv-img-box {
    display: none;
  }

  .animation-features-path {
    display: none;
  }

  .left-right-serv-con {
    margin-top: 0px;
  }
}