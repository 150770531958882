.container {
  position: relative;
}

.sidebar {
  position: fixed;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50px;
  z-index: 100;
  width: 27px;
  background-color: #363636;
  padding: 14px;
  text-align: center;
  color: #fff;
}

.signal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #555657;
  margin: 8px 0;
  cursor: pointer;
}

.active-dot {
  background-color: #568BFA;
}

.section-names {
  position: fixed;
  left: 45px; 
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}
.name-section{
  color: #474747;
  margin: 8px 0;
  cursor: pointer;
}
.active-section {
  color: white; 
}




@media (max-width:1440px) and (min-width:1199.9px) {
 
}
@media (max-width:1200px) and (min-width:991.9px) {

}
@media (max-width:992px) and (min-width:767.9px) {
.hide-side{
  display: none !important;
}
}
@media (max-width:768px) and (min-width:575.9px) {
  .hide-side{
    display: none !important;
  }
}
@media (max-width:576px) and (min-width:424.9px) {
  .hide-side{
    display: none !important;
  }
}
@media (max-width:425px) and (min-width:319.9px) {
  .hide-side{
    display: none !important;
  }
}
@media (max-width:320px) and (min-width:0px) {
  .hide-side{
    display: none;
  }
}