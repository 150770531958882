@font-face {
  font-family: Clash-Display;
  src: url("../assets/fonts/ClashDisplay-Regular.otf");
}
@font-face {
  font-family: Montserrat;
  src: url("../assets/fonts/Montserrat-Regular.ttf");
}
.form-box {
  padding: 30px;
  background-color: #150f22;
  border-radius: 10px;
  border: 1px solid #568bfa;
}
.heading {
  font-family: Clash-Display;
  font-weight: 600;
  font-size: 65px;
  color: white;
  text-align: center;
  line-height: 68px;
}
.sub-text {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  text-align: center;
  line-height: 22px;
}
.form-input {
  width: 100%;
  border: 1px solid #568bfa;
  height: 75px;
  padding: 5px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  padding-left: 7px;
  border-radius: 5px;
  color: white !important;
  /* background: linear-gradient(90deg, #231349 100%, #150f22 89%); */
  background: linear-gradient(#231349 0%, #150f22e3 100%)
}
.text-input {
  width: 100%;
  border: 1px solid #568bfa;
  padding: 5px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  padding-left: 7px;
  border-radius: 5px;
  resize: none;
  color: white !important;
  background: linear-gradient(90deg, #231349 100%, #150f22 89%);
}
::placeholder {
  color: white !important;
  padding-left: 7px;
}
.form-input option {
  background-color: #231349;
  padding: 10px;
}

.butn {
  font-family: Clash-Display;
  font-weight: 500;
  border: none;
  background-color: transparent;
  display: table;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  position: relative;
  transform: skew(-15deg); /* Add skew transformation */
  z-index: 1; /* Ensure button content is below pseudo-element */
}

.butn::before {
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "";
  width: 50%;
  height: 100%;
  background: transparent;
  border: 1px solid #568bfa;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Ensure pseudo-element is above button content */
}

.butn .butn-text {
  font-family: Clash-Display;
  font-weight: 500;
  color: white;
  position: relative;
  z-index: 2; /* Ensure text is above the pseudo-element */
}

.butn:hover::before {
  background: #568bfa;
  width: 100%;
}

@media (max-width: 1440px) and (min-width: 1199.9px) {
}
@media (max-width: 1200px) and (min-width: 991.9px) {
}
@media (max-width: 992px) and (min-width: 767.9px) {
  .space-s {
    margin-top: 20px;
  }
}
@media (max-width: 768px) and (min-width: 575.9px) {
  .space-s {
    margin-top: 20px;
  }
  .space-m {
    margin-top: 20px;
  }
}
@media (max-width: 576px) and (min-width: 424.9px) {
  .space-s {
    margin-top: 20px;
  }
  .space-m {
    margin-top: 20px;
  }
}
@media (max-width: 425px) and (min-width: 319.9px) {
  .space-s {
    margin-top: 20px;
  }
  .space-m {
    margin-top: 20px;
  }
}

.button-6 {
  all: unset;
  box-sizing: border-box;
  height: 57px;
  width: 125px;
}

.button-6 .overlap-group-5 {
  height: 57px;
  position: relative;
}

.button-6 .text-wrapper-6 {
  color: #ffffff;
  font-family: Clash-Display;
  font-size: 18px;
  font-weight: 600;
  left: 28px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.button-6 .subtract-5 {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.button-6 .property-1-7-default {
  width: 102px;
}

.button-6 .property-1-7-variant-2 {
  background-image: url(../assets/images/subtract-20.svg);
  background-size: 100% 100%;
  width: 119px;
}
