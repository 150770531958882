/* 
.slider-wrapper {
  position: relative;
}

.button-container {
  position: absolute;
  top: 50%;
  left: 0; 
  width: 100%;
  display: flex;
  justify-content:space-between;
  padding:15px; 
}

.prev-button,
.next-button {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #568BFA;
  border: none;
  cursor: pointer;
}

.next-button {
  background-color: #007bff; 
  color: white;
}
.prev-button {
  background-color: #007bff;
  color: white;
}

.accordion-wrapper {
  display: flex;
  gap: 20px; 
}


  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .custom-prev-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #fff;
  }
  
  .custom-next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1; 
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #fff;
  }
  
  .fa-angles-left{
    font-size: 25px;
  }
  .fa-angles-right{
    font-size: 25px;
  }

   */

   .slider-wrapper {
    position: relative;
  }
  
  .button-container {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
  
  .prev-button,
  .next-button {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: none !important;
    border: none;
    cursor: pointer;
  }
  
  .next-button {
    color: #818181;
  }
  
  .prev-button {
    color: #818181;
  }
  
  .accordion-wrapper {
    display: flex;
    gap: 25px;
  }
  
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  
  .custom-prev-arrow,
  .custom-next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #fff;
  }
  
  .fa-angles-left,
  .fa-angles-right {
    font-size: 25px;
  }
  @media (max-width:768px) and (min-width:575.9px) {
    .button-container{
      top: 30%;
    }
}
  @media (max-width:576px) and (min-width:424.9px) {
    .button-container{
      top: 50%;
    }
    .fa-angles-left,
    .fa-angles-right {
      font-size:18px;
    }
}
  @media (max-width:425px) and (min-width:319.9px) {
    .button-container{
      top: 20%;
    }
    .fa-angles-left,
    .fa-angles-right {
      font-size:18px;
    }
}
@media (max-width:320px) and (min-width:0px) {
.button-container{
  top: 20%;
}
.fa-angles-left,
.fa-angles-right {
  font-size: 18px;
}
}


  
  