@font-face {
  font-family: Clash-Display;
  src: url("../assets/fonts/ClashDisplay-Regular.otf");
}
.heading {
  font-family: Clash-Display;
  font-weight: 600;
  font-size: 65px;
  color: white;
  text-align: center;
  line-height: 68px;
}
.sub-text {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  line-height: 20px;
  text-align: center;
}
.sub-heading {
  font-family: Clash-Display;
  font-weight: 700;
  font-size: 35px;
  color: white;
  line-height: 50px;
}
.sub-para {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  line-height: 24px;
}
/* .main-img{
    width: 600px;
    position: relative;
    overflow: hidden;
    margin: auto;
} */
.highlight {
  width: 300px;
  height: 200px;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.full-width {
  width: 100%;
}

.main-img img {
  animation: bounce 1.5s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-25px);
  }
}
/* OurProducts.css */

.highlight img {
  animation: blink 1s infinite alternate; /* You can adjust the animation duration and other properties */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.btn {
  font-family: Clash-Display;
  font-weight: 500;
  border: none;
  padding: 10px;
  font-size: 18px;
  position: relative;
  transform: skew(-15deg); /* Add skew transformation */
  z-index: 1; /* Ensure button content is below pseudo-element */
}

.btn::before {
  transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "";
  width: 50%;
  height: 100%;
  background: transparent;
  border: 1px solid #568bfa;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; /* Ensure pseudo-element is above button content */
}

.btn .btn-text {
  font-family: Clash-Display;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: white;
  position: relative;
  z-index: 2; /* Ensure text is above the pseudo-element */
}

.btn:hover::before {
  background: #568bfa;
  width: 100%;
}

@media (max-width: 1440px) and (min-width: 1199.9px) {
}
@media (max-width: 1200px) and (min-width: 991.9px) {
}
@media (max-width: 992px) and (min-width: 768px) {
  #myRow {
      flex-direction: row-reverse;
  }

  #myCol {
      order: -1;
  }
}

@media (max-width: 768px) and (min-width: 575.9px) {
  #myRow {
    flex-direction: row-reverse;
}

#myCol {
    order: -1;
}
}
@media (max-width: 576px) and (min-width: 424.9px) {
  .hide-txt {
    display: none;
  }
}
@media (max-width: 425px) and (min-width: 319.9px) {
  .hide-txt {
    display: none;
  }
  .sub-heading {
    font-size: 28px !important;
    line-height: 38px !important;
  }
}
@media (max-width: 320px) and (min-width: 0px) {
  .hide-txt {
    display: none;
  }
  .sub-heading {
    font-size: 28px !important;
    line-height: 38px !important;
  }
}

.button {
  all: unset;
  box-sizing: border-box;
  height: 57px;
}

.button .overlap-group {
  height: 57px;
  position: relative;
}

.button .text-wrapper {
  color: #ffffff;
  font-family: "Clash Display-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 28px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.button .subtract {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.button.default {
  width: 125px;
}

.button.variant-2 {
  width: 133px;
}

.button.default .overlap-group {
  width: 130px;
}

.button.variant-2 .overlap-group {
  background-image: url(../assets/images/subtract-6.svg);
  background-size: 100% 100%;
  width: 158px;
}
