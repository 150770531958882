@font-face {
    font-family: Clash-Display;
    src: url('../assets/fonts/ClashDisplay-Regular.otf');
  }
  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-Regular.ttf');
  }
.connect-box{
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    height: 270px;
    background: #568BFA;
}
.blur{
    opacity: 0.3;
}
.icon-box{
    width: 60px;
    height: 60px;
    display: table;
    margin: auto;
}
.text{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: white;
    line-height: 16px;
    text-align: center;
}
.social-box{
    width: 22px;
    height: 22px;
}
.flex{
    display: flex;
}


@media (max-width:1440px) and (min-width:1199.9px) {}
@media (max-width:1200px) and (min-width:991.9px) {
    .text{
        font-size: 12px;
    }
}
@media (max-width:992px) and (min-width:767.9px) {
    .space-s{
        margin-top: 20px;
    }
}
@media (max-width:768px) and (min-width:575.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
}
@media (max-width:576px) and (min-width:424.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
}
@media (max-width:425px) and (min-width:319.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
}