@font-face {
  font-family: Clash-Display;
  src: url("../assets/fonts/ClashDisplay-Regular.otf");
}

@font-face {
  font-family: Montserrat;
  src: url("../assets/fonts/Montserrat-Regular.ttf");
}

.newsletter-heading {
  font-family: Clash-Display;
  font-weight: 500;
  font-size: 64px;
  color: white;
}

.sub-news {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  line-height: 20px;
}

.text-div {
  width: 70%;
  height: 40px;
  border-bottom: 1px solid #5e5e5e;
}

.email-input {
  width: 100%;
  height: 40px;
  border: none;
  background-color: transparent;
  color: white;
}

.line {
  height: 300px;
}

.email-input:focus {
  border: none !important;
  outline: none;
}

.newsletter-box {
  width: 100%;
  padding: 10px;
  background-color: #150f22;
  border: 3px solid white;
  border-radius: 10px;
  display: table;
  margin: auto;
}

@media (max-width: 1440px) and (min-width: 1199.9px) {}

@media (max-width: 1200px) and (min-width: 991.9px) {}

@media (max-width: 992px) and (min-width: 767.9px) {
  .space-s {
    margin-top: 20px;
  }

  .line {
    display: none;
  }

  .newsletter-heading {
    font-size: 40px;
    line-height: 45px;
  }
}

@media (max-width: 768px) and (min-width: 575.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .line {
    display: none;
  }

  .newsletter-heading {
    font-size: 40px;
    line-height: 45px;
  }
}

@media (max-width: 576px) and (min-width: 424.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .line {
    display: none;
  }

  .newsletter-heading {
    font-size: 40px;
    line-height: 45px;
  }
}

@media (max-width: 425px) and (min-width: 319.9px) {
  .space-s {
    margin-top: 20px;
  }

  .space-m {
    margin-top: 20px;
  }

  .line {
    display: none;
  }

  .newsletter-heading {
    font-size: 40px;
    line-height: 45px;
  }
}

@media (max-width: 320px) and (min-width: 0px) {
  .line {
    display: none;
  }

  .newsletter-heading {
    font-size: 40px;
    line-height: 45px;
  }
}

.button-6 {
  all: unset;
  box-sizing: border-box;
  height: 57px;
  width: 125px;
}

.button-6 .overlap-group-5 {
  height: 57px;
  position: relative;
}

.button-6 .text-wrapper-6 {
  color: #ffffff;
  font-family: Clash-Display;
  font-size: 18px;
  font-weight: 600;
  left: 28px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.button-6 .subtract-5 {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.button-6 .property-1-7-default {
  width: 102px;
}

.button-6 .property-1-7-variant-2 {
  background-image: url(../assets/images/subtract-20.svg);
  background-size: 100% 100%;
  width: 119px;
}

.margin {
  margin-top: 20px;
  margin-left: 15px;
}


.frame-263 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 28px;
  position: relative;
}

.frame-271 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(35, 19, 73) 0%, rgba(21, 15, 34, 0.89) 100%);
  border: 1px solid;
  border-color: #568bfa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  left: 196px;
  overflow: hidden;
  padding: 51px;
  /* position: absolute;
  top: 2896px; */
  width: 100%;
}

.frame-272 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  position: relative;
}

.text-wrapper-160 {
  color: white;
  font-family: Clash-Display;
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 46.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-161 {
  color: #b1b1b1;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.group-121 {
  height: 40px;
  position: relative;
  width: 480px;
}

.line-10 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 39px;
  width: 478px;
}

.enter-your-email {
  color: #b1b1b1;
  font-family: Clash-Display;
  font-size: 20px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 322px;
}

.button-19 {
  position: relative !important;
}

.vector-28 {
  height: 69px;
  right: 150px;
  position: absolute;
  top: 4px;
  width: 3px;
}

.vector-29 {
  height: 69px;
  right: 150px;
  position: absolute;
  top: 222px;
  width: 3px;
}

.vector-30 {
  height: 1px;
  right: 13px;
  position: absolute;
  top: 74px;
  width: 137px;
}

.vector-31 {
  height: 3px;
  right: 13px;
  position: absolute;
  top: 220px;
  width: 137px;
}

@keyframes dotMoveDown {
  0% {
    transform: translateY(0px)
  }

  to {
    transform: translateY(70px)
  }
}

@keyframes dotMoveUp {
  0% {
    transform: translateY(70px)
  }

  to {
    transform: translateY(0)
  }
}

.animation-features-vertical {
  display: flex;
  flex-grow: 1;
  position: relative;
  height: 70px;
  width: 2px;
}

.animation-features-ball-up {
  background-color: #3a64fa;
  border-radius: 50%;
  height: 8px;
  margin-top: -3px;
  position: absolute;
  width: 8px
}

.animation-features-ball-down {
  background-color: #3a64fa;
  border-radius: 50%;
  height: 8px;
  margin-top: -3px;
  position: absolute;
  width: 8px
}

.animation-features-ball-down {
  animation: dotMoveDown 2s linear infinite
}

.animation-features-ball-up {
  animation: dotMoveUp 2s linear infinite
}

@media screen and (max-width: 1024px) {
  .animation-features-vertical {
    display: none;
  }
}