@font-face {
    font-family: Clash-Display;
    src: url('../assets/fonts/ClashDisplay-Regular.otf');
  }
  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-Regular.ttf');
  }
  .heading{
    font-family: Clash-Display;
    font-weight: 600;
    font-size: 65px;
    color: white;
    text-align: center;
    line-height: 68px;
}
.team-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden; /* Add this to clip the image to the border-radius */
}
.sub-box{
  width: 80px;
  height: 80px;
  display: table;
  margin: auto;
}
.team-image img {
  width: 100%; /* Make sure the image takes up the entire space of its container */
  height: 100%; /* Make sure the image takes up the entire space of its container */
  object-fit: cover; /* Adjust this property based on how you want the image to be fitted within its container */
}
.team-box {
  width: 100%;
  padding: 15px;
  background: linear-gradient(90deg, #231349 100%, #150F22 89%);
  border-radius: 10px;
  cursor: pointer;
  position: relative; /* Add this to establish a positioning context */
  z-index: 2; /* Set a higher z-index to ensure it appears above .border-connect */
}

.border-connect {
  height: 1px;
  width: 25%;
  rotate: 90deg;
  border: 1px solid #474747;
  position: relative;
  z-index: 1;
  margin: auto;
  margin-top: 12px;
}

.logo-connect {
  height: 1px;
  width: 100%;
  rotate: 90deg;
  border: 1px solid #474747;
  position: relative;
  z-index: 1;
  margin: auto;
  margin-top: 12px;
}
.full-border {
  height: 1px;
  width: 80%;
  border: 1px solid #474747;
  position: relative;
  z-index: 1;
  margin: auto;
  bottom: 25px;
}


.team-box:hover .fa-linkedin{
  color: #568BFA;
}
.team-box:hover{
  border: 1px solid #568BFA;
}
.fa-linkedin{
  font-size: 26px;
  color: #6C6C6C;
}
.flex-image{
  display: flex;
  justify-content: space-between;
}
.name{
  font-family: Clash-Display;
  font-weight: 500;
  font-size: 28px;
  color: white;
  line-height: 29px;
}
.role{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: white;
  line-height: 15px;
}

.name-team{
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    color: white;
    line-height: 15px;
}
.role-team{
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  color: white;
  line-height: 15px;
}
.arrow{
  width: 30px;
  height: 30px;
  border: 1px solid white;
  border-radius: 15px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
right: 30px;
}
.arrow:hover{
  text-decoration: none !important;
}
.total-cards{
  display: table;
  margin: auto;
}


@media (max-width:1440px) and (min-width:1199.9px) {
  .border-connect {
    width: 29%;
  }
}
@media (max-width:1200px) and (min-width:991.9px) {
  .border-connect {
    width: 35%;
  }
}
@media (max-width:992px) and (min-width:767.9px) {
    .space-s{
        margin-top: 20px;
    }
}
@media (max-width:768px) and (min-width:575.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
    .full-border{
      display: none;
    }
    .heading{
      font-size: 45px !important;
      line-height: 48px !important;
     }
     
}
@media (max-width:576px) and (min-width:424.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
    .full-border{
      display: none;
    }
    .heading{
      font-size: 45px !important;
      line-height: 48px !important;
     }
}
@media (max-width:425px) and (min-width:319.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
    .full-border{
      display: none;
    }
    .heading{
      font-size: 45px !important;
      line-height: 48px !important;
     }
}
@media (max-width:320px) and (min-width:0px) {
  .full-border{
    display: none;
  }
  .heading{
    font-size: 45px !important;
    line-height: 48px !important;
   }
}


.card {
  border: 1px solid #568BFA;
  background-color: #231349;
  overflow: hidden;
  position: relative;
  /* transition: width 0.6s ease; */
  cursor: pointer; /* Change cursor to indicate clickability */
}

.img-container {
  width: 145px;
}

.expand-content {
  display: none;
}
.fa-linkedin{
  position: absolute;
  right: 30px;
}
.card.expanded {
  width: 400px; /* Width when expanded */
}

.arrow {
  cursor: pointer;
  color: #fff; /* Adjust color as needed */
}

.arrow:hover {
  text-decoration: underline;
}
.team-logo{
  width: 60px;
  height: 60px;
}
.fa-linkedin{
  color: #568BFA;
}
.cards{
  margin: auto;
}


.shine-btn {
  align-items: center;
  background: linear-gradient(#080216, rgba(75, 57, 118)) padding-box,linear-gradient(320deg,#568BFA -10%,#3a64fa00 20%,#18c3fa00 30%,#568BFA 50%,#3a64fa00 70%,#18c3fa00 80%,#568BFA 110%) border-box;
  background-size: 200% 200%;
  border: 2px solid #0000;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  display: flex;
  padding: 35px 24px;
  text-decoration: none;
  font-family: Clash-Display;
  font-weight: 700;
  font-size: 18px;
  width: 80%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.shine-btn:hover {
  animation: a 1s ease-in-out .1s
}

@keyframes a {
  0% {
      background-position: 0 0
  }

  50% {
      background-position: 100% 100%
  }
}







