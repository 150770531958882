.button-8 {
  height: 57px;
  width: 125px;
  background: transparent;
  border: none;
}

.button-8 .overlap-group-2 {
  position: relative;
}

.button-8 .text-wrapper-3 {
  color: #ffffff;
  font-family: Clash-Display;
  font-size: 18px;
  font-weight: 00;
  left: 28px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  white-space: nowrap;
}

.button-8 .subtract-2 {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.button-8 .property-1-2-default {
  height: 57px;
  width: 111px;
}

.button-8 .property-1-2-variant-2 {
  background-image: url(../src/assets/images/subtract-3.svg);
  background-size: 100% 100%;
  height: 54px;
  width: 139px;
}

.button-8 .overlap-group-2.property-1-2-default .text-wrapper-3 {
  top: 18px;
}

.button-8 .overlap-group-2.property-1-2-variant-2 .text-wrapper-3 {
  top: 17px;
}
