@font-face {
    font-family: Clash-Display;
    src: url('../assets/fonts/ClashDisplay-Regular.otf');
  }
  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-Regular.ttf');
  }
  
#main :nth-of-type(1) {flex-grow: 1;}
#main :nth-of-type(2) {flex-grow: 1;}
#main :nth-of-type(3) {flex-grow: 1;}
#main :nth-of-type(4) {flex-grow: 1;}
#main :nth-of-type(5) {flex-grow: 1;}
#main :nth-of-type(6) {flex-grow: 1;}
#main :nth-of-type(7) {flex-grow: 1;}
#main :nth-of-type(8) {flex-grow: 1;}
#main :nth-of-type(9) {flex-grow: 1;}

#main-2 :nth-of-type(1) {flex-grow: 1;}
#main-2 :nth-of-type(2) {flex-grow: 1;}
#main-2 :nth-of-type(3) {flex-grow: 1;}
#main-2 :nth-of-type(4) {flex-grow: 1;}
#main-2 :nth-of-type(5) {flex-grow: 1;}
#main-2 :nth-of-type(6) {flex-grow: 2;}
#main-2 :nth-of-type(7) {flex-grow: 2;}

#main-3 :nth-of-type(1) {flex-grow: 1;}
#main-3 :nth-of-type(2) {flex-grow: 2;}
#main-3 :nth-of-type(3) {flex-grow: 2;}
#main-3 :nth-of-type(4) {flex-grow: 2;}
#main-3 :nth-of-type(5) {flex-grow: 2;}

#main-4 :nth-of-type(1) {flex-grow: 2;}
#main-4 :nth-of-type(2) {flex-grow: 2;}
#main-4 :nth-of-type(3) {flex-grow: 2;}
#main-4 :nth-of-type(4) {flex-grow: 2;}

#main-5 :nth-of-type(1) {flex-grow: 2;}
#main-5 :nth-of-type(2) {flex-grow: 2;}
#main-5 :nth-of-type(3) {flex-grow: 2;}

#main {
  width: 100%;
  height: 120px;
  display: flex;
  gap: 5px;
  position: relative;
  left: -10px;
}
#main-2 {
  width: 100%;
  height: 120px;
  display: flex;
  gap: 5px;
  position: relative;
  left: -10px;
}
#main-3 {
  width: 100%;
  height: 120px;
  display: flex;
  gap: 5px;
  position: relative;
  left: -10px;
}
#main-4 {
  width: 100%;
  height: 120px;
  display: flex;
  gap: 5px;
  position: relative;
  left: -10px;
}
#main-5 {
  width: 100%;
  height: 120px;
  display: flex;
  gap: 5px;
  position: relative;
  left: -10px;
}
.border-logobox{
  border: 1px solid #568BFA;
}
  .img-width-s{
    width: 100px;
    height: 100px;
    display: table;
    margin: auto;
  }  
  .img-width-m{
    width: 200px;
    height: 100px;
    display: table;
    margin: auto;
  }  

  .slider{
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;
  }
  .slider:hover .slider-track-2 {
    animation-play-state: paused;
    transition: transform 0.5s ease; 
  }
  
  .slider .slider-track-2 {
    animation: scroll-2 50s linear infinite;
    display: flex;
    justify-content: space-evenly;
    width: calc(220px * 13);
    transition: transform 0.5s ease; 
  }
  
  @keyframes scroll-2 {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  
  
  .slider:hover .slider-track {
    animation-play-state: paused;
    transition: animation-play-state 0.5s ease; 
  }
  
  .slider .slider-track {
    animation: scroll 50s linear infinite;
    display: flex;
    justify-content: space-evenly;
    width: calc(220px * 13);
    transition: transform 0.5s ease; 
    
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  .slider .slide{
    height: 142px;
    width: 110px;
    display: table;
    margin: auto;
  }

.scroll-box:hover{
  background-color: white !important;
}

  .scroll-box{
    width: 160px;
    height: 180px;
    padding: 20px;
    background:#231349;
    border: 1px solid #568BFA;
    border-radius: 10px;
    transition: transform 0.1s ease-in-out;
    /* border-image: linear-gradient(to bottom right, #568BFA, #0D0D0D, #568BFA) 1; */
  }

  
  /* .slide:hover{
    transform: scale(1.3); 
  } */
  
@media (max-width:1440px) and (min-width:1199.9px) {}
@media (max-width:1200px) and (min-width:991.9px) {}
@media (max-width:992px) and (min-width:767.9px) {
  .img-width-s{
    width: 70px;
  }  
  .img-width-m{
    width: 140px;
  }  
}
@media (max-width:768px) and (min-width:575.9px) {
  .img-width-s{
    width: 50px;
  }  
  .img-width-m{
    width: 100px;
  }  
}
@media (max-width:576px) and (min-width:424.9px) {
  .img-width-s{
    width: 45px;
  }  
  .img-width-m{
    width: 90px;
  }  
}
@media (max-width:425px) and (min-width:319.9px) {
  .img-width-s{
    width: 35px;
  }  
  .img-width-m{
    width: 70px;
  }  
}

