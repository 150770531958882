@font-face {
    font-family: Clash-Display;
    src: url('../assets/fonts/ClashDisplay-Regular.otf');
  }
  @font-face {
    font-family: Montserrat;
    src: url('../assets/fonts/Montserrat-Regular.ttf');
  }
  .heading{
    font-family: Clash-Display;
      font-weight: 600;
      font-size: 65px;
      line-height: 68px;
      color: white;
      text-align: center;
  }
  .acc-subheading{
    font-family: Clash-Display;
      font-weight: 600;
      font-size: 28px;
      color: white;
  }
  .sub-text{
    font-family: Montserrat;
    font-weight: 400;
      font-size: 18px;
      color: #B1B1B1;
      line-height: 22px;
      text-align: center;
  }
  .acc-subtext{
    font-family: Montserrat;
    font-weight: 400;
      font-size: 16px;
      color: white;
      line-height: 25px;
  }
  .center{
    display: table;
    margin: auto;
  }
  .horizontal-line {
    border-bottom: 1px solid #568BFA;
  }
  .acc-heading{
    font-family: Montserrat;
    font-weight: 700;
      font-size: 68px;
      color: white;
      line-height: 90px;
  }
  .acc-text{
    font-family: Montserrat;
    font-weight: 400;
      font-size: 16px;
      color: white;
      line-height: 20px;
  }
  .accordian-box{
    background: linear-gradient(to right, #231349 100%, #150F22 89%);
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
  .view-button{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: white;
    padding: 10px;
    border-radius: 25px;
    background-color: #343434;
    border: 1px solid #568BFA;
    margin-top: 50%;
  }
  .view-button i {
    margin-left: 7px; /* Adjust margin as needed */
}
.hide-button{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: white;
    padding: 10px;
    border-radius: 25px;
    background-color: #343434;
    border: 1px solid #568BFA;
  }
  .hide-button i {
    margin-left: 7px; /* Adjust margin as needed */
}



@media (max-width:1440px) and (min-width:1199.9px) {}
@media (max-width:1200px) and (min-width:991.9px) {}
@media (max-width:992px) and (min-width:767.9px) {
    .space-s{
        margin-top: 20px;
    }
    .acc-heading{
      font-size: 46px;
      line-height: 80px;
    }
}
@media (max-width:768px) and (min-width:575.9px) {
    .space-s{
        margin-top: 20px;
    }
    .acc-heading{
      font-size: 46px;
      line-height: 80px;
    }
    .space-m{
        margin-top: 20px;
    }
    .view-button{
      margin-top: 10%;
    }
}
@media (max-width:576px) and (min-width:424.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
    .view-button{
      margin-top: 10%;
      margin-bottom: 10%;
    }
    .acc-heading{
      font-size: 46px;
      line-height: 60px;
    }
    .accordian-box{
      width: 300px;
    }
}
@media (max-width:425px) and (min-width:319.9px) {
    .space-s{
        margin-top: 20px;
    }
    .space-m{
        margin-top: 20px;
    }
    .view-button{
      margin-top: 10%;
      margin-bottom: 10%;
    }
    .acc-heading{
      font-size: 46px;
      line-height: 60px;
    }
    .accordian-box{
      width: 300px;
    }
}
@media (max-width:320px) and (min-width:0px) {
  .view-button{
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .acc-heading{
    font-size: 46px;
    line-height: 60px;
  }
  .accordian-box{
    width: 300px;
  }
}