.custom{
    width: 80% !important;
    display: table;
    margin: auto;
}





