.heading {
  font-family: Clash-Display;
  font-weight: 600;
  font-size: 65px;
  color: white;
  text-align: center;
  line-height: 68px;
}
.sub-text {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #b1b1b1;
  text-align: center;
  line-height: 22px;
}
.center-content {
  display: table;
  margin: auto !important;
}
.blog-box {
  width: 100%;
  padding: 15px;
  border: 1px solid #568bfa;
  background-color: #150f22;
  border-radius: 10px;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.blog-box.zoom-effect:hover {
  transform: scale(1.1);
}
.blog-image {
  width: 100%;
  border-radius: 5px;
}
.blog-heading {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 16px;
  color: white;
  line-height: 22px;
}
.blog-text {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  color: white;
  line-height: 22px;
}
.limited-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.load {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  color: white;
  line-height: 22px;
  cursor: pointer;
}

@media (max-width: 1440px) and (min-width: 1199.9px) {
}
@media (max-width: 1200px) and (min-width: 991.9px) {
}
@media (max-width: 992px) and (min-width: 767.9px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}
@media (max-width: 768px) and (min-width: 575.9px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}
@media (max-width: 576px) and (min-width: 424.9px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}
@media (max-width: 425px) and (min-width: 319.9px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}
@media (max-width: 320px) and (min-width: 0px) {
  .heading {
    font-size: 45px;
    line-height: 48px;
  }
}

.button-9 {
  height: 57px;
  width: 125px;
  margin-left: 45%;
}

.button-9 .overlap-group-2 {
  position: relative;
}

.button-9 .text-wrapper-3 {
  color: #ffffff;
  font-family: Clash-Display;
  font-size: 18px;
  font-weight: 600;
  left: 28px;
  letter-spacing: 0;
  line-height: 18.6px;
  position: absolute;
  white-space: nowrap;
}

.button-9 .subtract-2 {
  height: 57px;
  left: 0;
  position: absolute;
  top: 0;
  width: 102px;
}

.button-9 .property-1-2-default {
  height: 57px;
  width: 111px;
}

.button-9 .property-1-2-variant-2 {
  background-image: url(../assets/images/subtract-3.svg);
  background-size: 100% 100%;
  height: 54px;
  width: 139px;
}

.button-9 .overlap-group-2.property-1-2-default .text-wrapper-3 {
  top: 18px;
}

.button-9 .overlap-group-2.property-1-2-variant-2 .text-wrapper-3 {
  top: 17px;
}
