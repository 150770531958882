@font-face {
  font-family: Clash-Display;
  src: url('../assets/fonts/ClashDisplay-Regular.otf');
}
body{
  margin: 0px;
  padding: 0px;
}
.fixed-nav
{
  position: fixed;
    z-index: 3;
    width: 100%;
    top: 50px;
}
section{
  padding: 130px 0px 130px 0px;
}

.logo-box{
    width: 177.80px !important;
    height: 50px !important;
  }
  .w-100{
    width: 100%;
  }
  .dropdown-toggle::after {
    position: relative;
    top: 3px;
    left: 3px;
  }

  .halfbutton {
    font-family: Clash-Display;
    font-weight: 500;
    border: none;
    padding: 6px;
    font-size: 18px;
    position: relative;
    background-color:transparent;
    transform: skew(-15deg); /* Add skew transformation */
    z-index: 1; /* Ensure button content is below pseudo-element */
  }
   
  .halfbutton::before {
    transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    content: '';
    width: 36%;
    height: 100%;
    background: transparent;
    border: 1px solid #568BFA;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; 
    
  }
  
  .halfbutton .halfbutton-text {
    font-family: Clash-Display;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: white;
    position: relative;
    z-index: 2; /* Ensure text is above the pseudo-element */
    /* background-color: #080216; */
  }
  
  .halfbutton:hover::before {
    background: #568BFA;
    width: 100%;
  }

  .mr-35{
    margin-right: 80px;
  }

  .button-7 {
    height: 57px;
  }
  
  .button-7 .overlap-group-4 {
    height: 57px;
    position: relative;
    background: transparent;
    border: none;
  }
  
  .button-7 .text-wrapper-5 {
    color: #ffffff;
    font-family: Clash-Display;
    font-size: 18px;
    font-weight: 600;
    left: 36px;
    letter-spacing: 0;
    line-height: 18.6px;
    position: absolute;
    top: 21px;
    white-space: nowrap;
  }
  
  .button-7 .subtract-4 {
    height: 57px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 102px;
  }
  
  .button-7.property-1-5-default {
    width: 125px;
  }
  
  .button-7.property-1-5-variant-2 {
    width: 125px;
  }
  
  .button-7.property-1-5-default .overlap-group-4 {
    width: 219px;
  }
  
  .button-7.property-1-5-variant-2 .overlap-group-4 {
    background-image: url(../assets/images/subtract-5.svg);
    background-size: 100% 100%;
    width: 247px;
  }
  

  
  
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    font-family: Clash-Display;
    font-weight: 700;
    color: white;
  }
  .nav-link{
    font-family: Clash-Display;
    font-weight: 600;
    font-size: 18px;
    color: white !important;
    margin-left: 30px;
  }
  .active-indicator {
    display: none; /* Initially hide the image */
}

.nav-item .nav-link.active + .active-indicator {
    display: block; /* Display the image when the nav-link has the active class */
    margin: auto;
    position: relative;
    left: 15px;
    width: 40px;
}
  .nav-link:hover{
    color: white;
    font-weight: 800;
  }
  .navbar-nav{
    margin: auto;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
    background-image: url('../assets/images/icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .dropdown-menu{
    top: 60px !important;
    width: 935px !important;
    position: absolute !important;
    left: -350px !important;
    padding: 0px !important;
    border-radius: 10px;
    /* background-color: rgba(255, 255, 255, 0.14);    */
    background-color: rgba(0, 0, 0, 0.9);
  }
  .bg-blur{
    background-color:#568BFA;  
    border-radius: 10px;
  }

  .select-button{
      font-family: Clash-Display;
      font-weight: 400;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      width: 100%;
      height: 45px;
      padding: 10px;
      border-radius: 10px;
      background:none;
      cursor: pointer;
      border: none;
      margin-top: 5px;
      margin-bottom: 5px;
  }

.select-button.active {
  background-color: rgba(0, 0, 0, 0.3); /* Change this to your desired background color */
  color: #ffffff; /* Change this to your desired text color */
  font-weight: 600;
}
.header-box{
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headericon{
  width: 35px;
  height: 35px;
}
.link-heading{
  font-family: Clash-Display;
  font-weight: 500;
  font-size: 18px;
  color: white;
  text-decoration: none;
}
.link-subtext{
  font-family: Clash-Display;
  font-weight: 300;
  font-size: 10px;
  color: white;
  letter-spacing: 1px;
  text-decoration: none; /* Remove underline */
}
.content{
  margin-left: 15px;
}
.header-content{
  margin-top: 20px;
  cursor: pointer;
  padding: 5px;
}
.header-content:hover{
  background-color: #568BFA;
  color: white;
  border-radius: 10px;
}





@media (max-width:1440px) and (min-width:1199.9px) {
 
}
@media (max-width:1200px) and (min-width:991.9px) {
  .nav-link{
    font-size: 12px;
   }
   .btn .btn-text{
    font-size: 13px !important;
   }
   .dropdown-menu {
    width: 800px !important;
   }
   .content{
    margin-left: 25px;
   }
}
@media (max-width:992px) and (min-width:767.9px) {
  .nav-link{
    font-size: 16px;
   }
   .btn .btn-text{
    font-size: 14px !important;
   } 
  .dropdown-menu {
    width: 725px !important;
    left: 22px !important;
   }
   .link-subtext{
    display: none;
   }
   .nav-item .nav-link.active + .active-indicator {/* Display the image when the nav-link has the active class */
   display: none;
}
}
@media (max-width:768px) and (min-width:575.9px) {
  .nav-link{
    font-size: 16px;
   }
   .btn .btn-text{
    font-size: 14px !important;
   }
   .dropdown-menu {
    width: 540px !important;
    left: 30px !important;
}
   .link-subtext{
    display: none;
   }
   .link-heading{
    font-size: 14px;
   }
   .select-button{
    font-size: 15px !important;
   }
   .header-box{
    width: 50px;
    height: 50px;
   }
   .nav-item .nav-link.active + .active-indicator {/* Display the image when the nav-link has the active class */
    display: none;
 }
}
@media (max-width:576px) and (min-width:424.9px) {
  .nav-link{
    font-size: 14px;
   }
   .btn .btn-text{
    font-size: 14px !important;
   }
   .dropdown-menu {
    width: 375px !important;
    left: 30px !important;
}
   .link-subtext{
    display: none;
   }
   .link-heading{
    font-size: 14px;
   }
   .select-button{
    font-size: 15px !important;
   }
   .header-box{
    width: 50px;
    height: 50px;
   }
   .nav-item .nav-link.active + .active-indicator {/* Display the image when the nav-link has the active class */
    display: none;
 }
}
@media (max-width:425px) and (min-width:319.9px) {
  .nav-link{
    font-size: 14px;
   }
   .btn .btn-text{
    font-size: 14px !important;
   }
   .dropdown-menu {
    width: 360px !important;
    left: -20px !important;
}
   .link-subtext{
    display: none;
   }
   .link-heading{
    font-size: 14px;
   }
   .select-button{
    font-size: 15px !important;
   }
   .header-box{
    width: 50px;
    height: 50px;
   }
   .nav-item .nav-link.active + .active-indicator {/* Display the image when the nav-link has the active class */
    display: none;
 }
}
@media (max-width:320px) and (min-width:0px) {
  .nav-link{
    font-size: 14px;
   }
   .btn .btn-text{
    font-size: 14px !important;
   }
   .dropdown-menu {
    width: 360px !important;
    left: -25px !important;
}
   .link-subtext{
    display: none;
   }
   .link-heading{
    font-size: 14px;
   }
   .select-button{
    font-size: 15px !important;
   }
   .header-box{
    width: 50px;
    height: 50px;
   }
   .nav-item .nav-link.active + .active-indicator {/* Display the image when the nav-link has the active class */
    display: none;
 }
}

